import React from "react"
import "./index.scss"

const IntroHeading = ({ ...props }) => (
  <h2 className="intro-heading">
    {props.copy}
  </h2>
)

export default IntroHeading
