import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import IntroHeading from "src/components/IntroHeading"

const breadcrumbs = [
  { url: "demo-video", handle: "Demo Video" },
]

const DemoVideo = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "excellerator.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="Demo Video - ExCellerator" />
          <SubpageBanner image={background} heading="Demo Video" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="wrapper">
              <IntroHeading copy="For assistance in using the exCellerator, please watch this short demonstrational video. One simple step can lead to a more complete sample for your patients – this video will help you incorporate that step properly." />
              <div className="video__video">
                <iframe src={"https://www.youtube.com/embed/FowBsDO9cPs"} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default DemoVideo
